"use client";
import { useSearchParams } from "next/navigation";
import { useEffect } from "react";

export default function ReferralHandler() {
  const searchParams = useSearchParams();
  const referralCode = searchParams?.get("referralCode");

  useEffect(() => {
    async function handleReferralCode() {
      if (
        referralCode &&
        typeof referralCode === "string" &&
        referralCode.length === 12
      ) {
        try {
          const response = await fetch("/api/referral", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ referralCode }),
          });

          if (!response.ok) {
            console.error("Error setting referral code");
          }
        } catch (error) {
          console.error("Error setting referral code:", error);
        }
      }
    }

    handleReferralCode();
  }, [referralCode]);

  return null;
}
